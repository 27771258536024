import './App.scss';
import Nav from '../NavComponent/Nav';
import Home from '../HomeComponent/Home';
import { useState } from 'react';

function App() {

  const [searchText, setSerachText] = useState("" as string);

  const [selectedEnv, setSelectedEnv] = useState<string>("PROD");

  return (
    <>
      <Nav searchText={searchText} setSerachText={setSerachText} onEnvChange={(env: string) => setSelectedEnv(env)}></Nav>
      <div className="row mt-4">
        <div className="col-sm-12">
          <div className="container">
            <Home searchText={searchText} selectedEnv={selectedEnv}></Home>
          </div>
          <br />
        </div>
      </div>
    </>
  );
}

export default App;
