import './Nav.scss';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, InputBase } from '@material-ui/core';
import { Search, Menu as MenuIcon } from '@material-ui/icons';
import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }),
);

type TParams = { searchText: string, setSerachText: any, onEnvChange: any }

function Nav({ searchText, setSerachText, onEnvChange }: TParams) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedEnv, setSelectedEnv] = useState<string>("PROD");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const envChange = (env: string) => {
    setSelectedEnv(env);
    onEnvChange(env);
    handleClose();
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="primary" className="glossy-effect-bg">
          <Toolbar className="container">
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
              Mobile {window.location.origin.toLowerCase().indexOf("mobile.") < 0 ? "Non PROD" : ""} Dashboard
            </Typography>
            {window.location.origin.toLowerCase().indexOf("mobile.") < 0 &&
              <>
                <Button
                  className="menu-button"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="secondary"
                  onClick={handleClick}>
                  {selectedEnv}
                </Button>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem selected={selectedEnv === "DEV"} onClick={() => envChange("DEV")} className="menu-button">
                    <ListItemText primary="DEV" />
                  </StyledMenuItem>
                  <StyledMenuItem selected={selectedEnv === "SYST"} onClick={() => envChange("SYST")} className="menu-button">
                    <ListItemText primary="SYST" />
                  </StyledMenuItem>
                  <StyledMenuItem selected={selectedEnv === "UAT"} onClick={() => envChange("UAT")} className="menu-button">
                    <ListItemText primary="UAT" />
                  </StyledMenuItem>
                  <StyledMenuItem selected={selectedEnv === "PROD"} onClick={() => envChange("PROD")} className="menu-button">
                    <ListItemText primary="PROD" />
                  </StyledMenuItem>
                </StyledMenu>
              </>
            }
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                placeholder="Search Apps"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchText}
                onChange={(e) => setSerachText(e.target.value)}
              />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}

export default Nav;
