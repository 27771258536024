import { Button, Card } from '@material-ui/core';
import { App, AppEnvUrl } from '../../interfaces/app.interface';
import './AppCard.scss';

type TParams = { app: App, selectedEnv: string }

function standardMobileAzureWebURL(appId: string, env: string): string | undefined {
    if (appId === "") { 
        return undefined;
    }
    switch (env) {
        case "DEV":
            return `https://mobilenprd.penndot.gov/${appId}_dev/`;
        case "SYST":
            return `https://mobilenprd.penndot.gov/${appId}_syst/`;
        case "UAT":
            return `https://mobilenprd.penndot.gov/${appId}_uat/`;
        case "PROD":
            return `https://mobile.penndot.gov/${appId}/`;
        default:
            return undefined;
    }
}

function customWebURL(appEnv: AppEnvUrl, env: string): string | undefined {
    switch (env) {
        case "DEV":
            return appEnv.dev;
        case "SYST":
            return appEnv.syst;
        case "UAT":
            return appEnv.uat;
        case "PROD":
            return appEnv.prod;
        default:
            return undefined
    }
}

export function AppCard({ app, selectedEnv }: TParams) {
    const onAppCardClick = () => {
        const url = typeof(app.AppUrl) === "string" 
            ? standardMobileAzureWebURL(app.AppUrl, selectedEnv) 
            : customWebURL(app.AppUrl, selectedEnv);
        if (url) {
            window.open(url);
        }
    }

    return (
        <Card className={"app-card theme-" + app.ThemeId} onClick={onAppCardClick}>
            <div className="row app-card-header glossy-effect">
                <div className="col app-card-name mw-100pc-130px" title={app.AppName}>
                    {app.AppName.length > 14 ? app.AppName.substr(0, 14) + "..." : app.AppName}
                </div>
                <div className="col mw-130">
                    <img src={app.IconUrl} alt="app-icon" className="app-icon"></img>
                </div>
            </div>
            <div className="row app-card-body glossy-effect">
                <div className="col-12 app-card-description">
                    {app.AppDescription.length > 50 ? <>{app.AppDescription.substr(0, 50) + "... "}
                        <Button size="small" className="btn-view-more" data-toggle="tooltip" data-placement="top" title={app.AppDescription}>View more</Button>
                    </> : app.AppDescription}
                </div>
            </div>
        </Card>
    );
}

export default AppCard;