import { useState, useEffect } from 'react';
import { AppGroup } from '../../interfaces/app.interface';
import AppCard from '../AppCardComponent/AppCard';
import './ApplicationGroup.scss';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(20),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(14),
            paddingTop: 5,
        },
    }),
);

type TParams = { searchText: string, selectedEnv: string, appGroup: AppGroup, isExpandAll:boolean }

function ApplicationGroup({ searchText, selectedEnv, appGroup, isExpandAll }: TParams) {

    const classes = useStyles();
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleChange = () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    useEffect(() => {
        setExpanded(isExpandAll);
    }, [isExpandAll]);

    return (
        <>
            <div className={classes.root}>
                <Accordion expanded={expanded} onChange={handleChange()} >
                    <AccordionSummary
                    className={"app-group theme-1 app-accordion-body glossy-effect theme-" + appGroup.ThemeId}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Typography className={classes.heading}>{appGroup.GroupName}</Typography>
                        <Typography className={classes.secondaryHeading}>{appGroup.GroupDesc}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.root}>
                            <div className="row">
                                {appGroup.Applications && appGroup.Applications.length > 0 && appGroup.Applications.filter(x =>
                                    searchText.length === 0 ||
                                    x.AppName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                                    x.AppDescription.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
                                ).map(app =>
                                    <div className="col-12 col-lg-6 col-xl-4 col-xxxl-3 app-card-col pt-4" key={app.AppId}>
                                        <AppCard app={app} selectedEnv={selectedEnv}></AppCard>
                                    </div>
                                )}
                            </div>  </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default ApplicationGroup;