import { useEffect } from 'react';
import { useState } from 'react';
import { AppGroup } from '../../interfaces/app.interface';
import { AppService } from '../../services/app.service';
import './Home.scss';
import ApplicationGroup from '../AppGroupComponent/ApplicationGroup';
import { Button } from '@material-ui/core';

type TParams = { searchText: string, selectedEnv: string }

function Home({ searchText, selectedEnv }: TParams) {

    const [appGroups, setAppGroups] = useState([] as AppGroup[]);

    const [isExpandAll, setIsExapndAll] = useState(true as boolean); 

    useEffect(() => {
        let appServive = new AppService();

        appServive.GetAllApps().then(
            appsResponse => {
                setAppGroups(appsResponse.data);
            }
        );
    }, []);

    return (
        <>
            <div className="row mb-4">
                <div className="col-12 text-right">
                    <Button variant="contained" color="primary" className="mr-4 expand-collapse-all-button" onClick={()=>setIsExapndAll(true)}>
                        Expand All
                    </Button>
                    <Button variant="contained" color="secondary" className="expand-collapse-all-button" onClick={()=>setIsExapndAll(false)}>
                        Collapse All
                    </Button>
                </div>
            </div>

            {appGroups && appGroups.length > 0 && appGroups.map(appGroup =>
                <>
                    {(appGroup.Applications && appGroup.Applications.length > 0 && appGroup.Applications.filter(x =>
                        searchText.length === 0 ||
                        x.AppName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        x.AppDescription.toLowerCase().indexOf(searchText.toLowerCase()) >= 0).length > 0) &&
                        <div className="mb-4" key={appGroup.GroupId}>
                            <ApplicationGroup searchText={searchText} selectedEnv={selectedEnv} appGroup={appGroup} isExpandAll={isExpandAll} ></ApplicationGroup>
                        </div>}
                </>)}
        </>
    )
}

export default Home;